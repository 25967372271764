//export const baseURI = "https://api.fit4life.app";
export const baseURI = "https://fit4life-api-test.herokuapp.com";
//export const baseURI = "http://localhost:8080";

/*
 "scripts": {
    "start": "react-scripts start",
    "build": "react-scripts build",
    "test": "react-scripts test",
    "eject": "react-scripts eject"
  },
  */